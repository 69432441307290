// This file was automatically generated from admin.home.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.Home = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.HomeBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.HomeBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  if (opt_data.is_sevenrooms_admin) {
    output.append('<div id="page-admin-home"><div class="part-activity-stream"><ul>');
    var activityList8253 = opt_data.activities;
    var activityListLen8253 = activityList8253.length;
    for (var activityIndex8253 = 0; activityIndex8253 < activityListLen8253; activityIndex8253++) {
      var activityData8253 = activityList8253[activityIndex8253];
      output.append('<li>', soy.$$escapeHtml(activityData8253.action_formatted), '</li>');
    }
    output.append('</ul></div></div>');
  }
  return opt_sb ? '' : output.toString();
};
