// This file was automatically generated from admin.activitystream.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.ActivityStream = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.content.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.ActivityStreamBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.ActivityStreamBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="part-activity-stream"><div><form class="ajaxify" action="/admin/activitystream" method="GET"><div class="float-left"><select name="cat"><option value="ALL">ALL</option>');
  var categoryList7453 = opt_data.content.categories;
  var categoryListLen7453 = categoryList7453.length;
  for (var categoryIndex7453 = 0; categoryIndex7453 < categoryListLen7453; categoryIndex7453++) {
    var categoryData7453 = categoryList7453[categoryIndex7453];
    output.append('<option value="', soy.$$escapeHtml(categoryData7453), '" ', (categoryData7453 == opt_data.content.category) ? 'selected="selected"' : '', '>', soy.$$escapeHtml(categoryData7453), '</option>');
  }
  output.append('</select>&nbsp;&nbsp;</div>');
  Nightloop.Templates.Widget.GoldButton({text: 'FILTER', size: 'small', id: 'activity-stream-submit-btn'}, output);
  output.append('<div class="float-end"></div></form></div><p><br/>', (opt_data.content.num_results == 0) ? (opt_data.content.is_first_results) ? 'No results found' : 'No more results found' : (opt_data.content.num_results < opt_data.content.limit) ? (opt_data.content.is_first_results) ? 'Showing (' + soy.$$escapeHtml(opt_data.content.num_results) + ') result' + ((opt_data.content.num_results != 1) ? 's' : '') : 'Showing last (' + soy.$$escapeHtml(opt_data.content.num_results) + ') result' + ((opt_data.content.num_results != 1) ? 's' : '') : (opt_data.content.is_first_results) ? 'Showing first (' + soy.$$escapeHtml(opt_data.content.limit) + ') results' : 'Showing next (' + soy.$$escapeHtml(opt_data.content.limit) + ') results', '  ', (opt_data.content.num_results == opt_data.content.limit) ? '<a class="ajaxify" href="/admin/activitystream?c=' + soy.$$escapeHtml(opt_data.content.cursor) + '&l=' + soy.$$escapeHtml(opt_data.content.limit) + '&cat=' + soy.$$escapeHtml(opt_data.content.category) + '">Next (' + soy.$$escapeHtml(opt_data.content.limit) + ')</a>' : '', '</p><ul>');
  var activityList7516 = opt_data.content.activities;
  var activityListLen7516 = activityList7516.length;
  for (var activityIndex7516 = 0; activityIndex7516 < activityListLen7516; activityIndex7516++) {
    var activityData7516 = activityList7516[activityIndex7516];
    output.append('<li>', soy.$$escapeHtml(activityData7516.action_formatted), '</li>');
  }
  output.append('</ul>', (opt_data.content.num_results == opt_data.content.limit) ? '<a class="ajaxify" href="/admin/activitystream?c=' + soy.$$escapeHtml(opt_data.content.cursor) + '&l=' + soy.$$escapeHtml(opt_data.content.limit) + '&cat=' + soy.$$escapeHtml(opt_data.content.category) + '">Next (' + soy.$$escapeHtml(opt_data.content.limit) + ')</a>' : '', '</div><script type="text/javascript">$(document).ready(function (){Pmp.Utils.GoldButtonClickSubmit(\'#activity-stream-submit-btn\');});<\/script>');
  return opt_sb ? '' : output.toString();
};
